p.Month {
  @apply inline-block;
  width: 4rem;
}
p.Year {
  @apply inline-block;
  @apply pl-1;
  width: 6rem;
}

p.CVV {
  @apply inline-block;
  @apply pl-4;
  width: 12rem;
}

.elm-card-svg {
  width: max-content;
  height: max-content;
  @apply m-auto;
}

.Name label,
.Number label,
.Month label,
.Year label,
.CVV label {
  @apply pt-3;
  @apply w-full;
  @apply block;
  @apply text-gray-900;
  @apply text-lg;
}

.Name input,
.Number input,
.Month input,
.Year input,
.CVV input {
  @apply p-2;
  @apply shadow;
  @apply border;
  @apply border-gray-100;
  @apply w-full;
  @apply text-lg;
}

@media (min-width: 768px) {
  .Name label,
  .Number label,
  .Month label,
  .Year label,
  .CVV label,
  .Name input,
  .Number input,
  .Month input,
  .Year input,
  .CVV input {
    @apply text-base;
  }
}
