.stroke-black {
  stroke: #000;
}

.stroke-white {
  stroke: #fff;
}

.fill-black {
  fill: #000;
}

.fill-white {
  fill: #fff;
}

.linecap-round {
  stroke-linecap: round;
}

.linejoin-round {
  stroke-linejoin: round;
}

.stroke-width-2 {
  stroke-width: 2px;
}

.stroke-width-3 {
  stroke-width: 3px;
}

.stroke-width-4 {
  stroke-width: 4px;
}

.fill-none {
  fill: none;
}

.miterlimit {
  stroke-miterlimit: 10;
}

.fill-evenodd {
  fill-rule: evenodd;
}

.clip-evenodd {
  clip-rule: evenodd;
}

.isolate {
  isolation: isolate;
}

.fill-mpesa-green {
  fill: #d2e288;
}

.fill-mpesa-red {
  fill: #ec2127;
}

.fill-mpesa-red-dark {
  fill: #82221c;
}

.stroke-mpesa-orange {
  fill: #ec2127;
}

.stroke-mpesa-orange-light {
  fill: #cd8c5d;
}

.stroke-width-1-7 {
  stroke-width: 1.7157;
}
.stroke-miterlimit-2-6 {
  stroke-miterlimit: 2.6131;
}

.fill-tigo-pesa-yellow {
  fill: #ffcb0e;
}
.fill-tigo-pesa-yellow-1 {
  fill: #ffcc0f;
}
.fill-tigo-pesa-yellow-2 {
  fill: #ffd111;
}
.fill-tigo-pesa-yellow-3 {
  fill: #ffce17;
}
.fill-tigo-pesa-yellow-4 {
  fill: #fed607;
}
.fill-tigo-pesa-yellow-5 {
  fill: #fed507;
}
.fill-tigo-pesa-blue {
  fill: #243d78;
}
.fill-tigo-pesa-blue-1 {
  fill: #233d79;
}
.fill-tigo-pesa-white-1 {
  fill: #fffefd;
}
.fill-tigo-pesa-white-2 {
  fill: #fffffd;
}
.fill-tigo-pesa-white-3 {
  fill: #fffbf2;
}
