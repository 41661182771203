a.underline-animated:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.underline-animated:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.slideshow {
  overflow: hidden;
  position: relative;
}

.slideshow > div {
  display: flex;
  position: absolute;
  overflow: hidden;
  will-change: transform;
  animation: slideshow 25s linear infinite;
}

.slideshow > div > ul {
  float: left;
}

@keyframes slideshow {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.short-underline\:after:after {
  content: "—";
  display: block;
  position: relative;
  top: -23px;
  left: -3px;
}
