h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  /* font-family: "Playfair Display", serif; */
  /* font-family: 'Montserrat', serif; */
  font-family: "Overpass", sans-serif;
}

body {
  /* font-family: "Lato", sans-serif; */
  font-family: "Actor", sans-serif;
}
