.search-content {
  @apply absolute;
  @apply py-2;
  @apply bg-white;
  @apply shadow-xl;
  @apply border;
  @apply hidden;
  @apply rounded;
  @apply z-40;
  @apply opacity-0;
  top: calc(100% + 0.5em);
}

.search-content.open,
.search-input.open:before {
  @apply opacity-100;
  @apply block;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
